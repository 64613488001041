import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { AccountContext } from "../context/account";
import NavBar from "../components/navbar";
import Calendar from "../pages/Calendar/Calendar";
import Logout from "../pages/logout/Logout";
import Chars from "../pages/Chars/Chars";
import "./RouterConfig.css";

function RouterConfig() {
  const [accountInfo, setAccountinfo] = useContext(AccountContext);
  // const account = localStorage.getItem("authToken");
  const account = accountInfo;
  if(!account.token) {
    return <Login/>;
  }

  return (
    <div className='RoutedApp'>      
      <Router>
        <NavBar/>
        <div className='RoutedApp-Content'>
          <Routes>
            <Route path='/' caseSensitive={false} element={<Home/>} />
            <Route path='/chars' caseSensitive={false} element={<Chars/>} />
            <Route path='/calendar' caseSensitive={false} element={<Calendar/>} />
            <Route path='/logout' caseSensitive={false} element={<Logout/>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default RouterConfig;