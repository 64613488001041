import Char from "./Char";
import "./Chars.css"
import { useEffect, useState, useContext } from "react";
import api from "../../services/api";
import { AccountContext } from '../../context/account';

function Chars() {
    const [chars, setChars] = useState([]);
    const [accountInfo, setAccountInfo] = useContext(AccountContext);
    

    useEffect(() => {
        api.get('/account/listCharacters', {
            headers: {
              'Authorization': `Basic ${accountInfo.token}`
            }
          })
          .then((response) => {             
            setChars(response.data);            
          })
          .catch((err) => {
            console.log("ERRO: " + err)
          })  
        
    }, [])
    return (
      <div className="Chars">
        {chars.map(char => <Char name={char.name} datasource={char}></Char>)}
      </div>
    );
  }
  
  export default Chars;
  