import { useState, useContext } from "react";
import api from "../../services/api";
import { Buffer } from "buffer";
import { useSnackbar } from 'notistack';
import { AccountContext } from "../../context/account";
import "./Login.css"
import reiImg from "../../assets/rei.png"

function Login() {
  const [account, setAccount] = useState();
  const [password, setPassword] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [accountInfo, setAccountInfo] = useContext(AccountContext);
  const [loading, setLoading] = useState(false);

  const token=Buffer.from(`${account}:${password}`).toString('base64');

  const authenticate = (event, teste) => {
    if(loading) return;
    event.preventDefault();
    setLoading(true);
    api
      .post("/account/authenticate", {
        accountName: account,
        password: password
      }, {
        // headers: {
          // 'Authorization': `Basic NDE2Njc1OjA0ODUyMTE=`
        // }
      })
      .then((res) => {       
        setAccountInfo({token, ...res.data});
        enqueueSnackbar('Success', { variant: 'success' } );
        // localStorage.setItem("authToken", token);
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response.data.message}`, { variant: 'error' } );        
      })
      .finally(() => {
        setLoading(false);
      })
  }
    
 

  return (
    <div className="Login">
      <div className="Login-Box">
        <form onSubmit={authenticate}>
          <img src={reiImg} width="15%"/> <font className="Login-Title">TibiaRPGBrasil App</font>
          <hr/>
          <table className="Login-Table">
            <tbody>
              <tr>
                <td className="Login-Table-Input-Prepend">Account</td>
                <td className="Login-Table-Input"><input type="password" placeholder="Account" onChange={e => setAccount(e.target.value)}></input><br/></td>
              </tr>
              <tr>
                <td className="Login-Table-Input-Prepend">Password</td>
                <td className="Login-Table-Input"><input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}></input><br/></td>
              </tr>
              <tr>
                <td colSpan="2">                
                  <input 
                    type="submit" 
                    className={loading ? 'Login-Submit-Loading' : 'Login-Submit-Ready'}
                    onClick={authenticate} 
                    value={loading ? "..." : "Entrar"}/>
                </td>
              </tr>         
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}

export default Login;
