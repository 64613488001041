import { useContext } from "react";
import axios from "axios";
import { AccountContext } from "../context/account";

console.log("ENV:",process.env.NODE_ENV)
let url = 'http://localhost:4000'
if (process.env.NODE_ENV === 'production') {
  url = 'https://otapi.tibiarpgbrasil.com'
}

const api = axios.create({
  baseURL: url,
  withCredentials: true,
  rejectUnauthorized: false // Self Signed Certificate
});
  
api.interceptors.response.use((response) => response, (error) => {
  if(error.response.status === 401) {
    window.location.href = '/logout';
  } else {
    return Promise.reject(error);
  }
})

export default api;