import React, { useState } from 'react';

export const AccountContext = React.createContext();

export const AccountProvider = ({ children }) => {
  const [accountInfo, setAccountInfo] = useState({});

  return (
    <AccountContext.Provider value={[accountInfo, setAccountInfo]}>
    	{ children }
    </AccountContext.Provider>
  )
}