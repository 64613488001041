import { Link } from "react-router-dom";
import "./navbar.css"

function NavBar() {
    return (
        <div className="NavBar">
            <div className="NavBar-Row">
                <div className="NavBar-Left-Link"><Link to="/">Home</Link></div>
                <div className="NavBar-Left-Link"><Link to="/chars">Chars</Link></div>
                <div className="NavBar-Left-Link"><Link to="/calendar">Calendario de Eventos</Link></div>
                <div className="NavBar-Left-Right"><Link to="/logout">Deslogar</Link></div>
            </div>
        </div>
    );
}

export default NavBar;
