import "./Char.css"
import { useEffect } from "react";

function Char(props) {

  useEffect(() => {
    
  }, []);

  const vocations = ["None", "Sorcerer", "Druid", "Paladin", "Knight", "Master Sorcerer", "Elder Druid", "Royal Paladin", "Elite Knight"];
  const worlds = ["Danae", "Citeron", "Orion"];

  return (
    <div className="Char">
      <div className={props.datasource.deletion > 0 ? "Char-Deleted" : "Char-Active"}>
        {props.name}
        <hr></hr>
        Level: {props.datasource.level}<br/>
        Vocation: {vocations[props.datasource.vocation]}<br/>
        World: {worlds[props.datasource.world_id]}<br/>
        Status: {props.datasource.deletion === 0 ? "Active" : "Deleted"}
      </div>
    </div>
  );
}
  
  export default Char;
  