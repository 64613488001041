import React from 'react'
import { useState, useEffect, useContext, createRef } from 'react';
import FullCalendar, { CalendarApi } from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin, {Draggable} from '@fullcalendar/interaction'
import { AccountContext } from '../../context/account';
import api from '../../services/api';
import ExternalEvents from './ExternalEvents';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './Calendar.css'

//https://fullcalendar.io/docs/react
//https://codesandbox.io/s/damp-sun-uwh25g?file=/src/App.js
//https://fullcalendar.io/docs/event-dragging-resizing
//https://stackoverflow.com/questions/58567465/how-to-pass-multiple-plugins-to-full-calendar-component-in-react
//https://fullcalendar.io/docs/external-dragging-demo



function Calendar() {
  const [accountInfo, setAccountInfo] = useContext(AccountContext);
  const { enqueueSnackbar } = useSnackbar();
  const calendarRef = createRef();

  function loadEvents(fetchInfo, successCallback, failureCallback ) 
  { 
    api
        .get(`/calendar/events?startStr=${fetchInfo.startStr}&endStr=${fetchInfo.endStr}`, {
          headers: {
            'Authorization': `Basic ${accountInfo.token}`
          }
        })
        .then((response) => { 
          let input = response.data.map(r => { return {
            title: r.event_name, 
            start: r.event_date.replace(/T.*/, ""),
            extendedProps: {
              "code": r.event_type
            }
          }})
          successCallback(input)
        })
        .catch((err) => {
          failureCallback(err);
          console.log("ERRO: " + err)
        })     
  }

  const addEvent = (code, date) => {
    api
        .post(`/calendar/events`, {
          eventType: code,
          eventDate: date
        },
        {
          headers: {
            'Authorization': `Basic ${accountInfo.token}`
          }
        })
        .then((response) => { 
          enqueueSnackbar('Event added', { variant: 'success' } );
          calendarRef.current.getApi().refetchEvents();
        })
        .catch((err) => {          
          if(err.response.data.message) {
            enqueueSnackbar(`Calendar Error: ${err.response.data.message}`, { variant: 'error' } );   
          } else {
            enqueueSnackbar('Error on add new event to calendar', { variant: 'error' } );   
          }        
          console.log("ERROR:", err)
        })  
  }

  const removeEvent = (code, date) => {
    api
        .delete(`/calendar/events`, {
          data: {
            eventType: code,
            eventDate: date
          },
          headers: 
          {
            'Authorization': `Basic ${accountInfo.token}`
          }
        })
        .then((response) => { 
          enqueueSnackbar('Event removed', { variant: 'success' } );
          calendarRef.current.getApi().refetchEvents();
        })
        .catch((err) => {
          enqueueSnackbar('Error on remove event from calendar', { variant: 'error' } );   
          console.log("ERRO: " + err)
        })  
  }

  const handleEventClick = (arg) => { // bind with an arrow function
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: 'Are you sure?',
      text: `Remove event ${arg.event.title} on date ${arg.event.startStr}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((res) => {
      if(res.isConfirmed) {
        removeEvent(arg.event.extendedProps.code, arg.event.startStr)
      }
    })
  }

  const handleDrop = (info) => {
    let eventCode = info.draggedEl.getAttribute('event-code')
    let eventDate = info.date.toISOString().replace(/T.*/,'')    
    addEvent(eventCode, eventDate)
  }

  const handleEventReceive = (info) => {
    info.revert() // not allow event drop using default mechanism
  }  

  return (
    <div className="Calendar">
      {accountInfo.otapp_access >= 2 ? <ExternalEvents/> : ""}
      <FullCalendar
        ref={calendarRef}
        firstDay="1"
        locale="pt-br"
        contentHeight={"auto"}
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth"
        }}
        plugins={[ dayGridPlugin, interactionPlugin ]}
        events={loadEvents}          
        // editable={true}
        // droppable={true}
        eventStartEditable={false}
        eventClick={accountInfo.otapp_access >= 2 ? handleEventClick : null}
        eventReceive={accountInfo.otapp_access >= 2 ? handleEventReceive : null}
        drop={accountInfo.otapp_access >= 2 ? handleDrop : null}
        // eventReceive={(info) => {console.log(info.event); /*info.revert()*/}}
      />
      </div>
  )
}

export default Calendar;