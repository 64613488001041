import { useContext } from "react";
import { AccountContext } from "../../context/account";

function Logout() {    
    const [accountInfo, setAccountInfo] = useContext(AccountContext);    
    setTimeout(() => {
        setAccountInfo({});
        window.location.replace('/');
    }, 1000) 
    return <div/>
}

export default Logout;