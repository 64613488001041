import RouterConfig from "./navigation/RouterConfig";
import { SnackbarProvider } from "notistack";
import { AccountProvider } from "./context/account";
import './App.css';

function App() {
  return (
    <div className="App">
      <AccountProvider>
        <SnackbarProvider maxSnack={3}>
          <RouterConfig/>
        </SnackbarProvider>
      </AccountProvider>
    </div>
  );
}

export default App;
