import { Draggable } from "@fullcalendar/interaction";
import { createRef } from "react";
import { useEffect, useRef, useState, useContext } from "react";
import api from "../../services/api";
import { AccountContext } from "../../context/account";
import './ExternalEvents.css'

function ExternalEvents() {
  const containerRef = createRef();
  const dragEnabled = useRef(false);

  const [accountInfo, setAccountInfo] = useContext(AccountContext);

  const [eventTypes, setEventTypes] = useState([]);

  function loadEventTytpes() 
  { 
    api
        .get(`/calendar/eventTypes`, {
          headers: {
            'Authorization': `Basic ${accountInfo.token}`
          }
        })
        .then((response) => { 
          setEventTypes(response.data)
        })
        .catch((err) => {
          console.log("ERRO: " + err)
        })     
  }

  useEffect(() => {
    if(dragEnabled.current) return;
    dragEnabled.current = true; // avoid running twid due strict mode
    loadEventTytpes();
    new Draggable(containerRef.current, {
      itemSelector: '.fc-event-drag',
      eventData: function(eventEl) {
        return {
          title: eventEl.innerText
        };
      }
    });
  }, []);  

  return (
    <div ref={containerRef} className="ExternalEvents">
        <p/>
        <strong>Available Events</strong>

        {eventTypes.map(event => {
          if(event.schedule_enabled !== 0) {
            return  <div key={event.code} event-code={event.code} className='fc-event-drag fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event'>
                      <div className='fc-event-main'>{event.name}</div>
                    </div>
          } else {
            return  <div key={event.code} event-code={event.code} className='fc-event-disabled fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event'>
                      <div className='fc-event-main'>{event.name}</div>
                    </div>
          }
        })}        
        <p/>
    </div>
  )
}

export default ExternalEvents;